import React, { useContext, createContext, useMemo } from 'react'
import { useSnackbar } from 'notistack';
import { navigate } from "gatsby";
import { useCartData } from '../helpers';

import Api from '../Api'
const authDataContext = createContext()

export function AuthDataProvider({ children }) {
  const data = useAuth();
  return (
    <authDataContext.Provider value={data}>
      {children}
    </authDataContext.Provider>
  )
}

export const useAuthData = () => {
  return useContext(authDataContext);
}
const addresses = {
  billing: [
    {
      id: 1,
      houseNumber: 'B Wing Flat no 504',
      flatNumber: 'PBG Building',
      street: 'Near Powal Police Station',
      city: 'Mumbai',
      pinCode: '4000076',
      state: 'Maharashtra',
      country: 'India',
      type: 'billing'
    }, {
      id: 2,
      houseNumber: 'A Wing Flat no 555',
      building: 'RED Building',
      street: 'Near Powal Police Station',
      city: 'Mumbai',
      pinCode: '4000076',
      state: 'Maharashtra',
      country: 'India',
      type: 'billing'
    },
  ],
  shipping: [
    {
      id: 1,
      houseNumber: 'B Wing Flat no 504',
      building: 'PBG Building',
      street: 'Near Powal Police Station',
      city: 'Mumbai',
      pinCode: '4000076',
      state: 'Maharashtra',
      country: 'India',
      type: 'shipping'
    }, {
      id: 2,
      houseNumber: 'A Wing Flat no 555',
      building: 'RED Building',
      street: 'Near Powal Police Station',
      city: 'Mumbai',
      pinCode: '4000076',
      state: 'Maharashtra',
      country: 'India',
      type: 'shipping'
    },
  ]
}
function useAuth() {
  const [openLoginSignUp, setOpenLoginSignUp] = React.useState(false);
  const [profileAdresses, setProfileAddress] = React.useState('');
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [loggedInData, setUserData] = React.useState('');
  const [authLoader, setAuthLoader] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const cartData = useCartData();

  const fetchLoginRegister = async (payload) => {
    setAuthLoader(true);
    const response = await Api.registerUser(payload);
    console.log('response', response);
    setAuthLoader(false);
    return response;
  }

  const fetchLogin = async (payload) => {
    setAuthLoader(true);
    const response = await Api.loginUser(payload);
    console.log('response', response);
    if (response && response.data && response.data.find(l => l.Status?.toLowerCase() === 'success' || l.status?.toLowerCase() === 'success')) {
      localStorage.setItem('userData', JSON.stringify(response.data));
      setUserData(response.data);
      setIsLoggedIn(true);
      const url = window.location.href;
      setOpenLoginSignUp(false);
      let cartLocalStore = localStorage.getItem('cart') || '';
      const _cartLocalStore = cartLocalStore ? JSON.parse(cartLocalStore) : ''
      console.log("cartLocalStore", cartLocalStore)
      if (_cartLocalStore && _cartLocalStore.length > 0) {
        cartData?.addItemTocart();
      } else {
        cartData?.fetchCartApi();
      }
      if (url.includes('redirect-to-checkout')) {
        navigate('/checkout/');
      } else {
        navigate('/user/');
      }
      enqueueSnackbar({ message: 'Loggedin Successfully!', variant: 'success' }, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    setAuthLoader(false);

    return response;
  }

  const logoutuser = async (payload) => {
    setUserData('');
    setUserProfile('')
    setIsLoggedIn(false);
    localStorage.clear();
    cartData?.clearCart();
    navigate('/');
  }

  const getUserDetails = async () => {
    const payload = {
      Username: '',
      Mobilenumber: '',
      CustomerId: loggedInData[0]?.CustomerId
    }
    const response = await Api.getProfile(payload);
    console.log('response', response);
    if (response && response.ok) {
      const addressData = {
        billing: response.data.BillingAddress,
        shipping: response.data.ShippingAddress,
      }
      setProfileAddress(addressData);
      setUserProfile(response?.data);
    }
  }

  const addAddress = async (payload) => {
    const _payload = {
      ...payload,
      id: 0,
      loggedInUserId: loggedInData[0]?.UserId,
      customerId: loggedInData[0]?.CustomerId
    }
    console.log('_payload', _payload);
    const response = await Api.saveAddress(_payload);
    console.log('response', response);
    if (response && response.ok) {
      const data = response.data[0];
      const status = data.Status || data.status;
      const message = data.Message || data.Message;
      if (status.toLowerCase() === 'success') {
        getUserDetails();
        enqueueSnackbar({ message: message, variant: 'success' }, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        return 'success'
      } else {
        enqueueSnackbar({ message: 'Failed', variant: 'error' }, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        return 'failed'
      }
    }
  }

  React.useEffect(() => {
    const user = localStorage.getItem('userData');
    if (user) {
      setIsLoggedIn(true);
      cartData?.fetchCartApi();
      setUserData(JSON.parse(user));
    } else {
      setIsLoggedIn(false);
    }
  }, [])


  return {
    isLoggedIn,
    authLoader,
    loggedInData,
    fetchLoginRegister,
    fetchLogin,
    logoutuser,
    openLoginSignUp,
    setOpenLoginSignUp,
    profileAdresses,
    addAddress,
    getUserDetails,
    userProfile
  }
}
