import React, { useContext, createContext, useMemo } from 'react';
import { useSnackbar } from 'notistack';

import Api from '../Api'

const cartContext = createContext()

export function CartProvider({ children }) {
  const data = useCart()
  return (
    <cartContext.Provider value={data}>
      {children}
    </cartContext.Provider>
  )
}

export const useCartData = () => {
  return useContext(cartContext)
}

function useCart() {
  const [cartDataList, setCartDataList] = React.useState([]);
  const [cartCount, setCartCount] = React.useState('');
  const [cartSummary, setCartSummary] = React.useState('');
  const [cartLoading, setCartLoading] = React.useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartDataList));
    console.log('cartDataList', cartDataList)
  }, [cartDataList])

  const clearCart = async () => {
    // localStorage.removeItem('cart');
    const data = [];
    setTimeout(() => {
      localStorage.setItem('cart', data);
    }, 500);
    setCartDataList([]);
    setCartCount('');
    setCartSummary('');
  }

  const fetchCartApi = async () => {
    setCartLoading(true);
    const user = JSON.parse(localStorage.getItem('userData'));
    const payload = {
      CustomerId: user?.find(l => l.CustomerId && l.CustomerId)?.CustomerId,
      CustomerSessionId: '',
    }
    const response = await Api.getCart(payload);
    console.log('response', response);
    const numberOfItems = response.data?.NumberOfItems;
    const cartItems = response.data?.Products;
    setCartLoading(false);
    if (response.data) {
      setCartDataList(cartItems);
      if (numberOfItems > 0) {
      }
    } else {
      enqueueSnackbar({ message: 'Some error occurred!', variant: 'error' }, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  }
  const fetchAddToCartApi = async (cartPayload) => {
    const user = JSON.parse(localStorage.getItem('userData'));
    // const cart = JSON.parse(localStorage.getItem('cart'));
    console.log("user", user)
    console.log('cartDataList', cartDataList);
    console.log('cartPayload', cartPayload);
    const cartId = cartPayload?.find(l => l?.ShoppingCartId)?.ShoppingCartId || 0
    console.log('cart', cartPayload);
    const payload = {
      customerId: user?.find(l => l.CustomerId && l.CustomerId)?.CustomerId,
      subTotalAmount: cartSummary,
      taxAmount: 0,
      totalAmount: cartSummary,
      customerSessionId: '',
      numberofItems: cartPayload?.length,
      loggedInUserId: user?.find(l => l.CustomerId && l.CustomerId)?.UserId,
      products: cartPayload?.filter(l => l?.ProductId !== undefined)?.map((l) => (
        {
          productId: l?.ProductId,
          skuId: l?.SKUId,
          price: l?.Price || 0,
          quantity: l?.Quantity,
          totalPrice: l?.Quantity * l?.Price,
          shoppingCartId: cartId,
          shoppingCartDetailId: l?.ShoppingCartDetailId || 0
        }
      ))
    }
    console.log('payload', payload)
    const response = await Api.addToCartForCustomer(payload);
    console.log('response', response);
    fetchCartApi();
    const status = response.data.Status || response.data.status
    if (response.ok) {
      if (status) {
        if (status?.toLowerCase() === 'failed') {
          enqueueSnackbar({ message: 'Failed to update cart!', variant: 'error' }, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        } else {
          enqueueSnackbar({ message: 'Cart updated!', variant: 'success' }, {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      } else {
        enqueueSnackbar({ message: 'Failed to update cart!', variant: 'error' }, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    } else {
      enqueueSnackbar({ message: 'Failed to update cart!', variant: 'error' }, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  }
  const actionForCart = (item, fromCart) => {
    console.log("item", item)
    const userData = JSON.parse(localStorage.getItem('userData'));
    const successMessage = fromCart ? 'Updated!' : 'Added To Cart!';
    if (cartDataList.find(l => l.SKUId === item?.SKUId)) {
      const elementsIndex = cartDataList.findIndex(element => element.SKUId == item?.SKUId);
      console.log('elementsIndex', elementsIndex);
      let newArray = [...cartDataList]
      newArray[elementsIndex] = { ...newArray[elementsIndex], Quantity: newArray[elementsIndex].Quantity + (fromCart ? 1 : item.Quantity) }
      setCartDataList(newArray);
      if (userData && userData[0]?.UserId) {
        fetchAddToCartApi(newArray);
      } else { }
      if (!userData) {
        enqueueSnackbar({ message: successMessage, variant: 'success' }, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    } else {
      const cartPayload = [...cartDataList, item]
      setCartDataList(cartPayload);
      if (userData && userData[0]?.UserId) {
        fetchAddToCartApi(cartPayload);
      } else { }
      if (!userData) {
        enqueueSnackbar({ message: successMessage, variant: 'success' }, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    }
  }
  const addItemTocart = async (item, fromCart) => {
    console.log("item", item)
    const userData = JSON.parse(localStorage.getItem('userData'));
    await actionForCart(item, fromCart);
  }
  const decrementFromCart = async (item, fromCart) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const successMessage = fromCart ? 'Updated!' : 'Added To Cart!'
    if (cartDataList.find(l => l.SKUId === item.SKUId)) {
      const elementsIndex = cartDataList.findIndex(element => element.SKUId == item.SKUId);
      console.log('elementsIndex', elementsIndex);
      let newArray = [...cartDataList]
      newArray[elementsIndex] = { ...newArray[elementsIndex], Quantity: newArray[elementsIndex].Quantity - 1 }
      setCartDataList(newArray);
      if (userData) {
        fetchAddToCartApi(newArray);
      } else {
        enqueueSnackbar({ message: successMessage, variant: 'success' }, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    }
  }

  const deleteFromCart = async (cartItem, fromCart) => {
    const user = JSON.parse(localStorage.getItem('userData'));
    const userData = JSON.parse(localStorage.getItem('userData'));
    const cartLocalStorage = JSON.parse(localStorage.getItem('cart'));
    const successMessage = 'Deleted Successfully!'
    if (userData) {
      const payload = {
        LoggedInUserId: user.find(l => l.UserId && l.UserId)?.UserId,
        ShoppingCartId: cartItem?.ShoppingCartId,
        ShoppingCartDetailId: cartItem?.ShoppingCartDetailId
      }
      setCartLoading(true);
      const response = await Api.deletCartItem(payload);
      console.log('deleteresponse', response);
      if (response && response.ok) {
        const status = response.data[0]?.Status.toLowerCase();
        if (status === 'success') {
          if (cartLocalStorage?.length === 1) {
            clearCart();
          }
          enqueueSnackbar({ message: successMessage, variant: 'success' }, {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        } else {
          enqueueSnackbar({ message: 'Some error occurred!', variant: 'error' }, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
      fetchCartApi();
    } else {
      const newArray = await cartDataList.filter(function (obj) {
        return obj.SKUId !== cartItem?.SKUId;
      });
      setCartDataList(newArray);
      enqueueSnackbar({ message: successMessage, variant: 'success' }, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  }

  React.useEffect(() => {
    const _cartCount = cartDataList.reduce(function (accumulator, item) {
      return accumulator + item?.Quantity;
    }, 0);
    setCartCount(_cartCount);

  }, [cartDataList])

  React.useEffect(() => {
    const _cartSummary = cartDataList.reduce(function (accumulator, item) {
      return accumulator + item?.Price * item?.Quantity;
    }, 0);
    setCartSummary(_cartSummary);
  }, [cartDataList])

  React.useEffect(() => {
    const cartLocalStore = JSON.parse(localStorage.getItem('cart'))
    if (cartLocalStore && cartLocalStore.length > 0) {
      setCartDataList(cartLocalStore);
    }
  }, [])

  return {
    cartDataList,
    addItemTocart,
    cartCount,
    decrementFromCart,
    deleteFromCart,
    cartSummary,
    fetchCartApi,
    fetchAddToCartApi,
    cartLoading,
    clearCart
  }
}
