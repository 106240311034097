const create = require('apisauce').create
const isBrowser = typeof window !== 'undefined'
const apiUrl = 'https://amizaya.com/AmizayaAPI/api/'

const controllers = {
  Menu: 'Menu',
  Products: 'Products',
  Home: 'Home',
  User: 'User',
  Cart: 'Cart',
  Checkout: 'Checkout',
  Order: 'Order',
}
const getToken = () => isBrowser ? window.localStorage.getItem('token') : ''

const createApiClient = (baseURL = apiUrl) => {
  const api = create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Token: getToken()
    }
    // timeout: 15000
  })


  const getMainSubCategory = payload => {
    return api.post(`${controllers.Menu}/MainSubCategory`, payload)
  }
  const getListOfProducts = payload => {
    return api.post(`${controllers.Menu}/Products`, payload)
  }
  const getProductDetails = payload => {
    return api.post(`${controllers.Products}/ProductDetail`, payload)
  }
  const getHomeDetails = payload => {
    return api.post(`${controllers.Home}/HomeDetails`, payload)
  }
  const registerUser = payload => {
    return api.post(`${controllers.User}/RegisterUser`, payload)
  }
  const loginUser = payload => {
    return api.post(`${controllers.User}/Login`, payload)
  }
  const addToCartForCustomer = payload => {
    return api.post(`${controllers.Cart}/AddToCartForCustomer`, payload)
  }
  const deletCartItem = payload => {
    return api.post(`${controllers.Cart}/DeletCartItem`, payload)
  }
  const getCart = payload => {
    return api.post(`${controllers.Cart}/GetCart`, payload)
  }
  const saveAddress = payload => {
    return api.post(`${controllers.Checkout}/SaveAddress`, payload)
  }
  const getProfile = payload => {
    return api.post(`${controllers.User}/GetProfile`, payload)
  }
  const addUpdateOrders = payload => {
    return api.post(`${controllers.Order}/AddUpdateOrders`, payload)
  }
  const placeOrder = payload => {
    return api.post(`${controllers.Order}/PlaceOrder`, payload)
  }


  return {
    getMainSubCategory,
    getListOfProducts,
    getProductDetails,
    getHomeDetails,
    registerUser,
    loginUser,
    addToCartForCustomer,
    getCart,
    saveAddress,
    getProfile,
    addUpdateOrders,
    placeOrder,
    deletCartItem
  }
}

module.exports = createApiClient()
