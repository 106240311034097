import React, { useContext, createContext, useMemo } from 'react'

import Api from '../Api'
const allProductsDataContext = createContext()

export function AllProductsDataProvider({ children }) {
  const data = useProductsData()
  return (
    <allProductsDataContext.Provider value={data}>
      {children}
    </allProductsDataContext.Provider>
  )
}

export const useAllProductsData = () => {
  return useContext(allProductsDataContext)
}

function useProductsData() {
  const [mainSubCategoryData, setMainSubCategoryData] = React.useState([]);
  const [mainSubCategoryDataLoading, setMainSubCategoryDataLoading] = React.useState(false);
  const [listOfProductsLoading, setListOfProductsLoading] = React.useState(false);
  const [listOfProducts, setListOfProducts] = React.useState([]);
  const [productDetailsLoading, setProductDetailsLoading] = React.useState(false);
  const [homeDetailsLoading, setHomeDetailsLoading] = React.useState(false);
  const [productDetails, setProductDetails] = React.useState([]);
  const [homeDetails, setHomeDetails] = React.useState({});

  const getListOfProducts = async (payload) => {
    setListOfProductsLoading(true);
    const res = await Api.getListOfProducts(payload);
    console.log('responseproducts', res);
    if (res.ok) {
      const data = res.data;
      if (data) {
        setListOfProducts(data);
      }
    }
    setListOfProductsLoading(false);
  }

  const getProductDetails = async (payload) => {
    setProductDetailsLoading(true);
    const res = await Api.getProductDetails(payload);
    console.log('responseproductdetails', res);
    if (res.ok) {
      const data = res.data;
      if (data) {
        setProductDetails(data);
      }
    }
    setProductDetailsLoading(false);
  }

  const fetchCategories = async () => {
    setMainSubCategoryDataLoading(true);
    const data = JSON.stringify({ "username": "string" });
    const res = await Api.getMainSubCategory(data);
    console.log('response', res);
    if (res && res.data) {
      setMainSubCategoryData(res.data)
    }
    setMainSubCategoryDataLoading(false);
  }

  const fetchHomeDetails = async () => {
    setHomeDetailsLoading(true);
    let data = { SessionId: '23423423' }
    data = JSON.stringify(data);
    const res = await Api.getHomeDetails(data);
    console.log('fetchHomeDetailsResponse', res);
    if (res && res.data) {
      setHomeDetails(res.data)
    }
    setHomeDetailsLoading(false);
  }

  React.useEffect(() => {
    fetchCategories();
    fetchHomeDetails();
  }, [])


  return {
    mainSubCategoryData,
    mainSubCategoryDataLoading,
    getListOfProducts,
    listOfProductsLoading,
    listOfProducts,
    productDetailsLoading,
    productDetails,
    getProductDetails,
    fetchHomeDetails,
    homeDetailsLoading,
    homeDetails
  }
}
