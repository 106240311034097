import React, { useContext, createContext, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useCartData, useAuthData } from '../helpers';
import { navigate } from "gatsby";
import Api from '../Api'

const checkoutContext = createContext()

export function CheckoutProvider({ children }) {
  const data = useCheckout()
  return (
    <checkoutContext.Provider value={data}>
      {children}
    </checkoutContext.Provider>
  )
}

export const useCheckoutData = () => {
  return useContext(checkoutContext)
}

function useCheckout() {

  const [selectedBillingAddress, setSelectedBillingAddress] = React.useState('');
  const [selectedShippingAddress, setSelectedShippingAddress] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [orderData, setOrderData] = React.useState('');
  const [orderStatus, setOrderStatus] = React.useState('');
  const [paymentMode, setPaymentMode] = React.useState('');
  const authData = useAuthData();
  const cartData = useCartData();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCompletePayment = async (data) => {
    setLoading(true);
    const payload = {
      orderId: parseInt(orderData?.OrderId) || parseInt(data?.orderId),
      orderNumber: '',
      customerId: authData?.loggedInData[0]?.CustomerId,
      billingAddressId: selectedBillingAddress?.Id,
      shippingAddressId: selectedShippingAddress?.Id,
      shoppingCartId: cartData?.cartDataList[0]?.ShoppingCartId,
      thirdPartyPaymentOrderId: data?.razorpay_order_id || data?.paymentMethodId,
      thirdPartyPaymentId: data?.razorpay_payment_id || "",
      price: orderData?.TotalAmount,
      taxAmount: 0,
      shippingAmount: 0,
      totalPrice: orderData?.TotalAmount,
      loggedInUserId: authData?.loggedInData[0]?.UserId,
      orderStatus: 'open',
      thirdPartyPaymentSignature: data?.razorpay_signature || ''
    }
    const response = await Api.placeOrder(payload);
    setOrderData('');
    if (response && response.ok) {
      const data = response.data && response.data[0];
      const status = data.Status?.toLowerCase() || data.status?.toLowerCase();
      cartData?.fetchCartApi();
      // cartData?.clearCart();
      if (status === 'success') {
        setOrderStatus(data);
        navigate('/result');
      }
      else {
        setOrderStatus('');
        navigate('/result');
      }
    } else {
      enqueueSnackbar({ message: 'Failed!', variant: 'error' }, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    setLoading(false);
  }

  const handleInitiatePayment = async () => {
    setOrderStatus('');
    setLoading(true);
    const payload = {
      orderId: 0,
      orderNumber: '',
      customerId: authData?.loggedInData[0]?.CustomerId,
      shippingAddressId: selectedShippingAddress?.Id,
      billingAddressId: selectedBillingAddress?.Id,
      shoppingCartId: cartData?.cartDataList[0]?.ShoppingCartId,
      paymentId: 0,
      price: cartData?.cartSummary,
      taxAmount: 0,
      shippingAmount: 0,
      totalPrice: cartData?.cartSummary,
      loggedInUserId: authData?.loggedInData[0]?.UserId,
      orderStatus: 'open',
      PaymentType: paymentMode,
    }
    console.log('handleInitiatePayment', payload)
    const response = await Api.addUpdateOrders(payload);
    console.log('response', response);
    if (response && response.ok) {
      const data = response.data && response.data;
      if (data.ThirdPartyPaymentOrderId === "COD") {
        const payload = {
          paymentMethodId: data?.ThirdPartyPaymentOrderId,
          orderId: data?.OrderId
        }
        handleCompletePayment(payload);
        // setOrderStatus("Order placed!");
        // navigate('/result');
      } else {
        setOrderData(data);
      }
    } else {
      setOrderData('');
      enqueueSnackbar({ message: 'Failed!', variant: 'error' }, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    setLoading(false);
  }

  return {
    selectedBillingAddress,
    selectedShippingAddress,
    setSelectedBillingAddress,
    setSelectedShippingAddress,
    handleInitiatePayment,
    loading,
    orderData,
    handleCompletePayment,
    orderStatus,
    setPaymentMode
  }
}
