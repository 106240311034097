import React from 'react'
import { CssBaseline, NoSsr, IconButton } from '@material-ui/core';
import { ThemeProvider, StylesProvider } from './ThemeProvider';
import { AllProductsDataProvider, CartProvider, AuthDataProvider, CheckoutProvider } from '../helpers';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}
// eslint-disable-next-line react/display-name,react/prop-types
const RootProvider = ({ element, locale }) => {
  // const match = useMatch(':locale/')
  return (
    <NoSsr>
      <ThemeProvider>
        <StylesProvider>
          <CssBaseline />
          <SnackbarProvider hideIconVariant={false} ref={notistackRef}
            content={(key, message) => (
              <Alert key={key} variant='outlined' severity={message.variant}
                style={{ borderRadius: '25px', background: '#fff' }}
                onClose={() => onClickDismiss(key)}>
                <span onClick={() => onClickDismiss(key)}>{message.message}</span>
              </Alert>
            )}
          // action={(key) => (
          //   <IconButton onClick={onClickDismiss(key)}>
          //     <CloseIcon style={{
          //       fontSize: '20px',
          //       color: '#000'
          //     }} />
          //   </IconButton>
          // )}
          >
            <AllProductsDataProvider>
              <CartProvider>
                <AuthDataProvider>
                  <CheckoutProvider>
                    {element}
                  </CheckoutProvider>
                </AuthDataProvider>
              </CartProvider>
            </AllProductsDataProvider>
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider >
    </NoSsr >
  )
}

export default RootProvider
