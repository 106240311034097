// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-categories-[id]-js": () => import("./../../../src/pages/categories/[id].js" /* webpackChunkName: "component---src-pages-categories-[id]-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-[id]-[id]-js": () => import("./../../../src/pages/product/[id]/[id].js" /* webpackChunkName: "component---src-pages-product-[id]-[id]-js" */),
  "component---src-pages-product-[id]-js": () => import("./../../../src/pages/product/[id].js" /* webpackChunkName: "component---src-pages-product-[id]-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-result-js": () => import("./../../../src/pages/result.js" /* webpackChunkName: "component---src-pages-result-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

