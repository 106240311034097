import React from 'react'
import {
  ThemeProvider as MaterialThemeProvider,
  StylesProvider as MaterialStylesProvider,
  jssPreset
} from '@material-ui/core/styles'
import { create } from 'jss'

import theme, { defaultTheme } from './ConfigureTheme'


const ThemeProvider = ({ children }) => (
  <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
)

const StylesProvider = ({ children }) => (
  <MaterialStylesProvider >{children}</MaterialStylesProvider>
)

export {
  ThemeProvider,
  StylesProvider,
  theme,
  defaultTheme
}
